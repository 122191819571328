import React, { useEffect, useState } from 'react';
import { db, onSnapshot, collection, query, where, updateDoc, doc, deleteDoc, getDocs } from '../firebaseConfig';
import { getAuth } from "firebase/auth";
import clsx from 'clsx';
import { arrayRemove } from 'firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faBan } from '@fortawesome/free-solid-svg-icons';
import { FaSort, FaSortUp, FaSortDown } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const AdminPage = () => {
  const [users, setUsers] = useState([]);
  const [lists, setLists] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      const usersRef = collection(db, "users");
      const q = query(usersRef, where("role", "!=", ""));
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const usersData = [];
        querySnapshot.forEach((doc) => {
          usersData.push({ id: doc.id, ...doc.data() });
        });
        setUsers(usersData);
      });

      return () => {
        unsubscribe();
      };
    };

    const fetchLists = async () => {
      const listsRef = collection(db, "shoppingLists");
      const q = query(listsRef);
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const listsData = [];
        querySnapshot.forEach((doc) => {
          listsData.push({ id: doc.id, ...doc.data() });
        });
        setLists(listsData);
      });

      return () => {
        unsubscribe();
      };
    };

    fetchUsers();
    fetchLists();
  }, []);

  const changeUserRole = async (userId, isAdmin) => {
    const userDocRef = doc(db, "users", userId);
    const role = isAdmin ? 'admin' : 'user';
    await updateDoc(userDocRef, { role });
  };

  const findUserEmail = (uid) => {
    const user = users.find((user) => user.uid === uid);
    return user ? user.email : "";
  };

const [emailInput, setEmailInput] = useState("");
const addAdmin = async () => {
  const user = users.find(user => user.email === emailInput);
  if (user) {
    await changeUserRole(user.id, true);
    setEmailInput(''); 
  } else {
    console.log("No user found with this email");
  }
};


const [sortConfig, setSortConfig] = useState({ key: 'createdAt', direction: 'descending' });

const sortableLists = React.useMemo(() => {
  let sortableItems = [...lists];
  if (sortConfig !== null) {
    sortableItems.sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === 'ascending' ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });
  }
  return sortableItems;
}, [lists, sortConfig]);

const requestSort = (key) => {
  let direction = 'ascending';
  if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
    direction = 'descending';
  }
  setSortConfig({ key, direction });
};

const deleteShoppingList = async (listId) => {
  // First, delete all items that belong to the list
  const itemsRef = collection(db, "shoppingItems");
  const q = query(itemsRef, where("listId", "==", listId));
  const itemsSnapshot = await getDocs(q);
  itemsSnapshot.forEach((docSnapshot) => {
    const itemDocRef = doc(db, "shoppingItems", docSnapshot.id);
    deleteDoc(itemDocRef);
  });

  // Then delete the list itself
  const listDocRef = doc(db, "shoppingLists", listId);
  await deleteDoc(listDocRef);
};

const deleteUser = async (userId) => {
  try {
    // First, delete all lists that belong to the user
    const listsRef = collection(db, "shoppingLists");
    const q = query(listsRef, where("createdBy", "==", userId));
    const listsSnapshot = await getDocs(q);
    listsSnapshot.forEach(async (docSnapshot) => {
      await deleteShoppingList(docSnapshot.id);
    });

    // Now, handle the lists that are shared with the user
    const sharedListsRef = collection(db, "shoppingLists");
    const q2 = query(sharedListsRef, where("sharedWith", "array-contains", userId));
    const sharedListsSnapshot = await getDocs(q2);
    sharedListsSnapshot.forEach(async (docSnapshot) => {
      const listDocRef = doc(db, "shoppingLists", docSnapshot.id);
      await updateDoc(listDocRef, {
        sharedWith: arrayRemove(userId)
      });
    });

    // Then delete the user itself
    const userDocRef = doc(db, "users", userId);
    await deleteDoc(userDocRef);

    // Delete user from Firebase Authentication
    const authInstance = getAuth();
    const user = authInstance.currentUser;
    if (user) {
      await user.delete();
    }

  } catch (error) {
    console.error("Error deleting user:", error);
  }
};

  return (
    <div className="container mt-5">
      <h1 className="text-center mb-4">Admin Page</h1>
<Link to="/blogpost">Create Blog Post</Link>

      {/* User roles management */}
      <h2>User Roles</h2>
      <table className="table table-striped">
        <thead>
          <tr>
	    <th style={{textAlign: "left"}}>Email</th>
            <th>Admin</th>
            <th>Action</th>
          </tr>
        </thead>
  <tbody>
    {users.filter(user => user.role !== 'user').map(user => (
      <tr key={user.id}>
        <td style={{textAlign: "left"}}>{user.email}</td>
        <td>
          <label className="switch">
            <input type="checkbox" checked={user.role === 'admin'} onChange={() => changeUserRole(user.id, user.role !== 'admin')} />
            <span className={clsx('slider', { 'slider-checked': user.role === 'admin' })}></span>
          </label>
        </td>
        <td>
	  <button onClick={() => deleteUser(user.id)} style={{marginLeft: "10px", backgroundColor: "transparent", border: "none", cursor: "pointer"}}>
	    <FontAwesomeIcon icon={faTrashAlt} style={{ color: 'red' }} />
	  </button>
          <button style={{marginLeft: "10px", backgroundColor: "transparent", border: "none", cursor: "pointer"}}>
            <FontAwesomeIcon icon={faBan} style={{ color: 'red' }} />
          </button>
        </td>
      </tr>
    ))}
  </tbody>
      </table>
<div>
  <input 
    type="email" 
    placeholder="Enter email" 
    className="form-control" 
    onChange={(e) => setEmailInput(e.target.value)}
    value={emailInput}  // Add this line
  />
  <button onClick={addAdmin}>Add Admin</button>
</div>

      {/* Shopping list management */}
	<h2>Shopping Lists</h2>
	<table className="table table-striped">
	  <thead>
	    <tr>
  <th onClick={() => requestSort('createdAt')}>
      Date created
      {sortConfig && sortConfig.key === 'createdAt' ? 
        (sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />) : 
        <FaSort />
      }
    </th>
    <th onClick={() => requestSort('name')}>
      Name
      {sortConfig && sortConfig.key === 'name' ? 
        (sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />) : 
        <FaSort />
      }
    </th>
    <th onClick={() => requestSort('createdBy')}>
      Owner Email
      {sortConfig && sortConfig.key === 'createdBy' ? 
        (sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />) : 
        <FaSort />
      }
    </th>
	      <th>Action</th>
	    </tr>
	  </thead>
	  <tbody>
		{sortableLists.map((list) => (
	      <tr key={list.id}>
	        <td>{list.createdAt.toDate().toLocaleDateString()}</td>
	        <td>{list.name}</td>
	        <td>{findUserEmail(list.createdBy)}</td>
	        <td>
	          <button
	            onClick={() => deleteShoppingList(list.id)}
	            className="btn btn-danger"
	          >
	            <FontAwesomeIcon icon={faTrashAlt} />
	          </button>
	        </td>
	      </tr>
	    ))}
	  </tbody>
	</table>
    </div>
  );
};

export default AdminPage;
