import React, { useState, useEffect, useMemo } from 'react';
import { auth } from '../firebaseConfig';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import {
  collection,
  addDoc,
  query,
  where,
  onSnapshot,
  deleteDoc,
  doc,
  updateDoc,
  getDoc,
  getDocs,
} from 'firebase/firestore';
import { db } from '../firebaseConfig';
//import { firebase } from '../firebaseConfig';
import { log } from "../logger";
import { firestore } from "../firebaseConfig";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faCheck } from '@fortawesome/free-solid-svg-icons';
import { faPencilAlt, faShareAlt } from '@fortawesome/free-solid-svg-icons';
import { faStickyNote, faListCheck } from "@fortawesome/free-solid-svg-icons";

const ListSelectionModal = ({ lists, onSelect, onClose, showListSelectionModal, setShowListSelectionModal, onListNameUpdate, onListsUpdate, selectedListToShare, setSelectedListToShare, userId }) => {
  log("Rendering ListSelectionModal, showListSelectionModal:", showListSelectionModal);
  log("lists:", lists); // Add this line to log the 'lists' prop

const [user, setUser] = useState(null);
const auth = getAuth();

useEffect(() => {
  const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
    if (currentUser) {
      // User is signed in.
      setUser(currentUser);
    } else {
      // No user is signed in.
      setUser(null);
    }
  });

  // Clean up subscription
  return () => {
    unsubscribe();
  };
}, [auth]);

const deleteShoppingList = async (listId) => {
  // First, delete all items that belong to the list
  const itemsRef = collection(db, "shoppingItems");
  const q = query(itemsRef, where("listId", "==", listId));
  const itemsSnapshot = await getDocs(q);
  const deletePromises = itemsSnapshot.docs.map(docSnapshot => {
    const itemDocRef = doc(db, "shoppingItems", docSnapshot.id);
    return deleteDoc(itemDocRef);
  });

  await Promise.all(deletePromises);
  log("Items deleted");

  // Then delete the list itself
  const listDocRef = doc(db, "shoppingLists", listId);
  await deleteDoc(listDocRef);
  log("List deleted");
};

const handleDeleteList = async (list) => {
  try {
    if (window.confirm('Are you sure you want to delete this list? This action cannot be undone.')) {
      await deleteShoppingList(list.id);
      // Call onListsUpdate to trigger an update in the parent component
      onListsUpdate();
    }
  } catch (error) {
    console.error('Error deleting list:', error);
  }
};

const handleShareClick = async (list) => {
  log("List in handleShareClick:", list);

  const fetchListAndSetSelected = async () => {
    // Fetch the list from Firestore to ensure it has the latest data, including the sharedWith field
    const listRef = doc(db, "shoppingLists", list.id);
    const listSnapshot = await getDoc(listRef);
    if (listSnapshot.exists()) {
      const listData = listSnapshot.data();
      log("Fetched list data:", listData);
      setSelectedListToShare({ ...list, sharedWith: listData.sharedWith || [] });
    } else {
      log("List not found:", list);
    }
  };

  await fetchListAndSetSelected();
};

  const [editingList, setEditingList] = useState(null);
  const [newListName, setNewListName] = useState('');
  const [editingListName, setEditingListName] = useState('');

  const handleEditListName = (list) => {
    setEditingList(list);
    setEditingListName(list.name);
  };

const isUserOwner = (list) => {
  log("list.createdBy:", list.createdBy);
  log("userId:", userId);
  return list.createdBy === userId;
};

  const handleSaveListName = async (e) => {
    if (e.key === 'Enter' && editingListName.trim() !== '') {
      try {
        const listRef = doc(db, 'shoppingLists', editingList.id);
        await updateDoc(listRef, { name: editingListName });
        onListNameUpdate(editingList, { ...editingList, name: editingListName });
        setEditingList(null);
	setEditingListName(''); // Reset editingListName

        // Call onListsUpdate to trigger an update in the parent component
        onListsUpdate();
      } catch (error) {
        console.error('Error updating list name:', error);
      }
    }
  };
  if (!showListSelectionModal) {
    return null;
  }

//  const sortedLists = lists.slice().sort((a, b) => a.name.localeCompare(b.name));

const sortedLists = lists.slice().sort((a, b) => a.name.localeCompare(b.name));

const handleCreateNewList = async () => {
  try {
    const newListRef = await addDoc(collection(db, "shoppingLists"), {
      name: newListName,
      createdAt: new Date(),
      createdBy: user ? user.uid : null, // <- Traditional check instead of optional chaining
      sharedWith: [],
    });
    console.log("New list created with ID:", newListRef.id);
    setNewListName(""); // Clear the new list name input
  } catch (error) {
    console.error("Error creating new list:", error);
  }
};

return (
  <>
    <div className="modal show d-block" onClick={onClose}>
      <div className="modal-dialog" onClick={(e) => e.stopPropagation()}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Select a List</h5>
            <button type="button" className="btn-close" onClick={onClose}></button>
          </div>
          <div className="modal-body">
            <ul className="list-group">
                {[...new Map(sortedLists.map(list => [list.id, list])).values()].map((list, index) => (
                <li
                  key={list.id}
                  className="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
                  onClick={() => {
                    if (!editingList) {
                      onSelect(list.name, list.id);
                    }
                  }}
                >
                  {editingList && editingList.id === list.id ? (
                    <input
                      type="text"
                      value={editingListName}
                      onChange={(e) => setEditingListName(e.target.value)}
                      onKeyPress={handleSaveListName}
                    />
                 ) : (
                    <span>{list.name}</span>
                  )}
		    <div className={`icon-container`}>
		      {isUserOwner(list) && (
			<>
                        <FontAwesomeIcon icon={faPencilAlt} className="me-2" onClick={(e) => { e.stopPropagation(); handleEditListName(list); }} />
                        <FontAwesomeIcon icon={faShareAlt} className="me-2" onClick={() => handleShareClick(list)} />
	                <FontAwesomeIcon icon={faTrashAlt} onClick={(e) => { e.stopPropagation(); handleDeleteList(list); }} />
                      </>)}
                    </div>
                </li>
              ))}
            </ul>
            <div className="space-div"> </div>
            <form
              onSubmit={(e) => {
                e.preventDefault(); // Prevent the form from reloading the page
                handleCreateNewList();
              }}
            >
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter new list name"
                  aria-label="Enter new list name"
                  value={newListName}
                  onChange={(e) => setNewListName(e.target.value)}
                />
                <button
                  className="btn btn-outline-secondary"
                  type="submit"
                  disabled={!newListName} // Disable the button if newListName is empty
                >
                  Create New List
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </>
);
}

const ShoppingList = () => {
  const [inputValue, setInputValue] = useState('');
  const [items, setItems] = useState([]);
  const [user, setUser] = useState(null);
  const [userLists, setUserLists] = useState([]);
  const [updateKey, setUpdateKey] = useState(0);
  const [shoppingListName, setShoppingListName] = useState("My First List");
  const [showListSelectionModal, setShowListSelectionModal] = useState(false);
  const [selectedListId, setSelectedListId] = useState(null);
  const [selectedListToShare, setSelectedListToShare] = useState(null);
  const [loading, setLoading] = useState(true);
  const [sharedWithEmails, setSharedWithEmails] = useState([]);
  const [shareEmail, setShareEmail] = useState('');

  const forceListsUpdate = () => {
    setUpdateKey(updateKey + 1);
  };

// Add a new state variable
const [hasCreatedDefaultList, setHasCreatedDefaultList] = useState(false);

// Then, in your function, check the state variable before proceeding
let isCreatingDefaultList = false;

const createAndSetDefaultList = async () => {
  console.log('createAndSetDefaultList called, hasCreatedDefaultList:', hasCreatedDefaultList);
  if (hasCreatedDefaultList || isCreatingDefaultList) return; // If the default list has already been created or is being created, return early

  isCreatingDefaultList = true;

  const newList = {
    name: 'My First List',
    createdAt: new Date(),
    createdBy: user.uid,
    sharedWith: [],
  };

  const newListRef = await addDoc(collection(db, 'shoppingLists'), newList);
  const newListId = newListRef.id;

  await updateDoc(doc(db, 'users', user.uid), {
    defaultList: newListId,
  });

  setHasCreatedDefaultList(true); // Update the state variable
  isCreatingDefaultList = false; // Reset the creation status variable

  return newListId;
};

const handleSelectList = async (listName, listId) => {
  log("Selected list:", listName, listId);
  setShoppingListName(listName);
  setSelectedListId(listId);
  setShowListSelectionModal(false);

  // Save the selected list as the default list for the user
  try {
    const userRef = doc(db, 'users', user.uid);
    await updateDoc(userRef, { defaultList: listId });
  } catch (error) {
    console.error('Error setting default list:', error);
  }
};

  const openListSelectionModal = () => {
      console.log("Opening list selection modal");
    setShowListSelectionModal(true);
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      console.log('User state changed:', user);
      setUser(user);
    });
    return () => unsubscribe();
  }, []);

useEffect(() => {
  if (user) {
    const fetchLists = async () => {
      const ownedListsQuery = query(collection(db, 'shoppingLists'), where('createdBy', '==', user.uid));
      const sharedListsQuery = query(collection(db, 'shoppingLists'), where('sharedWith', 'array-contains', user.uid));
      const queries = [ownedListsQuery, sharedListsQuery];

      const unsubscribeUserLists = [];

      queries.forEach((q) => {
        const unsubscribe = onSnapshot(
          q,
          (querySnapshot) => {
            const lists = [];
            querySnapshot.forEach((doc) => {
              lists.push({ id: doc.id, name: doc.data().name, createdBy: doc.data().createdBy });
            });
            setUserLists((prevLists) => [...prevLists, ...lists]);
          },
          (error) => {
            console.error('Error fetching user lists:', error);
          }
        );
        unsubscribeUserLists.push(unsubscribe);
      });

const userRef = doc(db, 'users', user.uid);
const unsubscribeUser = onSnapshot(userRef, async (userDoc) => {
  if (userDoc.exists()) {
    const userData = userDoc.data();
    if (userData && 'defaultList' in userData) {
      const defaultListId = userData.defaultList;
      if (defaultListId) {
        const defaultListRef = doc(db, 'shoppingLists', defaultListId);
        const defaultList = await getDoc(defaultListRef);
        if (defaultList.exists()) {
          setShoppingListName(defaultList.data().name);
          setSelectedListId(defaultList.id);
        } else {
          const newListId = await createAndSetDefaultList();
          setShoppingListName('My First List');
          setSelectedListId(newListId);
        }
      } else {
        const newListId = await createAndSetDefaultList();
        setShoppingListName('My First List');
        setSelectedListId(newListId);
      }
    } else {
      const newListId = await createAndSetDefaultList();
      setShoppingListName('My First List');
      setSelectedListId(newListId);
    }
  } else {
    const newListId = await createAndSetDefaultList();
    setShoppingListName('My First List');
    setSelectedListId(newListId);
  }
  setLoading(false); // Set loading to false after fetching the default list
});


      return () => {
        unsubscribeUserLists.forEach((unsubscribe) => unsubscribe());
        unsubscribeUser();
      };
    };

    fetchLists();
  }
}, [user]);

useEffect(() => {
  if (user && selectedListId) {
    // Fetch user's shopping items
    const q = query(
      collection(db, 'shoppingItems'),
//      where('uid', '==', user.uid),
      where('listId', '==', selectedListId)
    );
    const unsubscribe = onSnapshot(
      q,
      (querySnapshot) => {
        const items = [];
        querySnapshot.forEach((doc) => {
          items.push({ id: doc.id, name: doc.data().name, quantity: doc.data().quantity, isChecked: doc.data().isChecked || false });
        });
        console.log("Fetched items:", items);
        setItems(items);
      },
      (error) => {
        console.error('Error fetching shopping list items:', error);
      }
    );

    return () => {
      unsubscribe();
    };
  }
}, [user, selectedListId]);

useEffect(() => {
  if (selectedListToShare) {
    console.log("Selected list to share:", selectedListToShare);
    console.log("Shared with:", selectedListToShare.sharedWith); // Add this line instead
//    setSelectedListToShare({ ...selectedListToShare, shareWith: selectedListToShare.sharedWith });
  }
}, [selectedListToShare]);

const getEmailsForUserIds = async (userIds) => {
  console.log("User IDs to fetch emails for:", userIds); // Add this line
  const userEmails = [];
  for (const userId of userIds) {
    const userDocRef = doc(db, "users", userId);
    const userDocSnap = await getDoc(userDocRef);
    if (userDocSnap.exists()) {
      const userData = userDocSnap.data();
      userEmails.push(userData.email);
      console.log("Fetched user email:", userData.email);
    } else {
      console.log("User not found for id:", userId);
    }
  }
  return userEmails;
};

useEffect(() => {
  if (selectedListToShare && selectedListToShare.sharedWith) {
    console.log("Fetching emails for user IDs:", selectedListToShare.sharedWith);
    getEmailsForUserIds(selectedListToShare.sharedWith).then((fetchedEmails) => {
      console.log("Fetched emails for shareWith:", fetchedEmails);
      setSharedWithEmails(fetchedEmails);
    });
  } else {
    setSharedWithEmails([]);
  }
}, [selectedListToShare]);

const handleListNameUpdate = (oldList, updatedList) => {
  setUserLists((prevLists) => {
    return prevLists.map((list) => {
      if (list.id === oldList.id) {
        return updatedList;
      }
      return list;
    });
  });
};

const addItem = async (e) => {
e.preventDefault();
if (inputValue.trim() !== '') {
try {
await addDoc(collection(db, 'shoppingItems'), {
uid: user.uid,
listId: selectedListId,
name: inputValue,
quantity: 1,
dateAdded: new Date(),
isChecked: false,
});
setInputValue('');
} catch (e) {
console.error('Error adding document: ', e);
}
}
};

const deleteItem = async (id) => {
try {
await deleteDoc(doc(db, 'shoppingItems', id));
} catch (e) {
console.error('Error deleting document: ', e);
}
};

const handleIncrement = async (item) => {
const itemRef = doc(db, "shoppingItems", item.id);
await updateDoc(itemRef, {
quantity: item.quantity + 1,
});
};

const handleDecrement = async (item) => {
const itemRef = doc(db, "shoppingItems", item.id);
await updateDoc(itemRef, {
quantity: item.quantity - 1,
});
};

const toggleChecked = async (item) => {
const itemRef = doc(db, 'shoppingItems', item.id);
await updateDoc(itemRef, {
isChecked: !item.isChecked, // Toggle isChecked based on the current value
});
};


const deleteAllCheckedItems = async () => {
  if (selectedListId) {
    if (window.confirm("Are you sure you want to delete all checked items from this list?")) {
    // Get the shoppingItems collection
    const shoppingItemsRef = collection(firestore, 'shoppingItems');

    // Query for checked items in the selected list
    const checkedItemsQuery = query(shoppingItemsRef, where('isChecked', '==', true), where('listId', '==', selectedListId));
    const checkedItemsSnapshot = await getDocs(checkedItemsQuery);

    // Delete checked items
    const deletePromises = checkedItemsSnapshot.docs.map((doc) => deleteDoc(doc.ref));
    await Promise.all(deletePromises);

    console.log('All checked items have been deleted.');
  }
  }
};

const deleteAllItems = async () => {
  if (selectedListId) {
    if (window.confirm("Are you sure you want to delete all items from this list?")) {

    console.log('Selected list ID:', selectedListId);

    // Get the shoppingItems collection
    const shoppingItemsRef = collection(firestore, 'shoppingItems');

    // Query for all items in the selected list
    const allItemsQuery = query(shoppingItemsRef, where('listId', '==', selectedListId));
    const allItemsSnapshot = await getDocs(allItemsQuery);

    console.log('Number of items to delete:', allItemsSnapshot.size);

    if (allItemsSnapshot.size === 0) {
      console.log('No items found.');
    } else {
      console.log('All shopping items:', allItemsSnapshot.docs.map(doc => doc.data()));

      // Delete all items
      const deletePromises = allItemsSnapshot.docs.map((doc) => deleteDoc(doc.ref));
      await Promise.all(deletePromises);

      console.log('All items have been deleted.');
    }
  } else {
    console.log('No list selected.');
  }
  }
};

const handleRemoveShare = async (email) => {
  try {
    const listRef = doc(firestore, "shoppingLists", selectedListToShare.id);
    const listSnapshot = await getDoc(listRef);
    const sharedWithUserIds = listSnapshot.data().sharedWith;

    // Find the corresponding user ID for the given email address
    const userIdToRemove = await getUserIdByEmail(email);

    if (userIdToRemove) {
      const updatedSharedWithUserIds = sharedWithUserIds.filter(
        (userId) => userId !== userIdToRemove
      );

      await updateDoc(listRef, { sharedWith: updatedSharedWithUserIds });

      // Update the sharedWith property of the selectedListToShare in your component state
      setSelectedListToShare({
        ...selectedListToShare,
        sharedWith: updatedSharedWithUserIds,
      });
    }
  } catch (error) {
    console.error("Error removing share:", error);
  }
};

const getUserIdByEmail = async (email) => {
  const userQuery = query(collection(db, "users"), where("email", "==", email));
  const querySnapshot = await getDocs(userQuery);
  let userId = null;

  querySnapshot.forEach((doc) => {
    userId = doc.id;
  });

  return userId;
};

const handleAddShare = async (email) => {
  try {
    // Find the corresponding user ID for the given email address
    const userIdToAdd = await getUserIdByEmail(email);

    if (userIdToAdd) {
      const listRef = doc(firestore, "shoppingLists", selectedListToShare.id);
      const listSnapshot = await getDoc(listRef);
      const sharedWithUserIds = listSnapshot.data().sharedWith;

      // Check if the user ID is not already in the sharedWith array
      if (!sharedWithUserIds.includes(userIdToAdd)) {
        const updatedSharedWithUserIds = [...sharedWithUserIds, userIdToAdd];

        await updateDoc(listRef, { sharedWith: updatedSharedWithUserIds });

        // Update the sharedWith property of the selectedListToShare in your component state
        setSelectedListToShare({
          ...selectedListToShare,
          sharedWith: updatedSharedWithUserIds,
        });

        // Empty the input field after successfully sharing the list
        setShareEmail('');
      } else {
        console.log("This user is already in the sharedWith list.");
      }
    } else {
      console.log("User not found for the given email address.");
    }
  } catch (error) {
    console.error("Error adding share:", error);
  }
};

const sortedItems = useMemo(() => {
return items
.slice()
.sort((a, b) => {
if (a.isChecked === b.isChecked) {
return a.name.localeCompare(b.name);
}
return a.isChecked ? 1 : -1;
});
}, [items]);

if (loading) {
  return (
    <div className="container mt-5">
      <h1 className="text-center">Loading...</h1>
    </div>
  );
}

return (
  <div className="container mt-5">
<ListSelectionModal
  key={updateKey}
  lists={userLists}
  onSelect={handleSelectList}
  onClose={() => setShowListSelectionModal(false)}
  showListSelectionModal={showListSelectionModal}
  setShowListSelectionModal={setShowListSelectionModal}
  onListNameUpdate={handleListNameUpdate}
  onListsUpdate={forceListsUpdate}
  selectedListToShare={selectedListToShare}
  setSelectedListToShare={setSelectedListToShare}
  userId={auth.currentUser.uid}
/>
{
selectedListToShare && (
  <div className="modal show d-block">
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">Share List: {selectedListToShare.name}</h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => setSelectedListToShare(null)}
          ></button>
        </div>
        <div className="modal-body">
         {log("selectedListToShare inside modal:", selectedListToShare)}
          {/* Add the content of your share modal here */}
          <div className="input-group mb-3">
<form
  className="input-group mb-3"
  onSubmit={(e) => {
    e.preventDefault();
    handleAddShare(shareEmail);
  }}
>
  <input
    type="email"
    className="form-control"
    placeholder="Enter email to share list"
    aria-label="Enter email to share list"
    value={shareEmail}
    onChange={(e) => setShareEmail(e.target.value)}
  />
  <button className="btn btn-outline-secondary" type="submit">
    Share
  </button>
</form>

          </div>
           <ul className="list-group">
		<h6>Shared with:</h6>
		{console.log('SharedWithEmails:', sharedWithEmails)}
               {sharedWithEmails.map((email, index) => (
              <li key={index} className="list-group-item d-flex justify-content-between align-items-center">{email} 
               <FontAwesomeIcon icon={faTrashAlt} className="text-danger" onClick={() => handleRemoveShare(email)} />
             </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  </div>
)
}

<h1 className="text-center mb-4" onClick={openListSelectionModal}>
{shoppingListName}
</h1>
<form onSubmit={addItem}>
<div className="input-group mb-3">
<input
type="text"
className="form-control"
placeholder="Add item"
value={inputValue}
onChange={(e) => setInputValue(e.target.value)}
/>
<button className="btn btn-primary" type="submit">
Add
</button>
</div>
</form>
<ul className="list-group">
{sortedItems.map((item) => (
<li
key={item.id}
className={`list-group-item d-flex justify-content-between align-items-center${item.isChecked ? ' checked' : ''}`}
>
<div
className="item-name-container"
onClick={() => toggleChecked(item)}
style={{ cursor: 'pointer', userSelect: 'none' }}
>
{item.isChecked && (
<FontAwesomeIcon
icon={faCheck}
className="me-2"
style={{ color: "green" }}
            />
          )}
          <span className="item-name">{item.name}</span>
        </div>
        <div className="d-flex align-items-center">
          <button
            className="btn btn-outline-secondary btn-sm me-2"
            onClick={() => handleDecrement(item)}
            disabled={item.isChecked || item.quantity <= 1}
          >
            -
          </button>
          <span className="item-quantity">{item.quantity}</span>
          <button
            className="btn btn-outline-secondary btn-sm ms-2 me-3"
            onClick={() => handleIncrement(item)}
            disabled={item.isChecked} 
         >
            +
          </button>
          <FontAwesomeIcon
            icon={faTrashAlt}
            onClick={() => deleteItem(item.id)}
            style={{ cursor: "pointer", color: "red" }}
          />
        </div>
      </li>
    ))}
  </ul>
<div className="d-flex justify-content-center mt-4">
  <button
    className="btn btn-success btn-sm me-2"
    onClick={() => deleteAllCheckedItems()}
  >
    <FontAwesomeIcon icon={faListCheck} />
  </button>
  <button 
    className="btn btn-danger btn-sm"
    onClick={() => deleteAllItems()}
  >
    <FontAwesomeIcon icon={faStickyNote} />
  </button>
</div>
</div>

);
};

export default ShoppingList;
