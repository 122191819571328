import React, { useState, useEffect } from 'react';
import { onAuthStateChanged, auth } from './firebaseConfig';
import './App.css?v=1';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './components/Login';
import UserPage from './components/UserPage';
import Registration from './components/Registration';
import UserManagement from './components/UserManagement';
import Lists from './components/Lists';
import Navbar from './components/Navbar';
import AdminPage from './components/AdminPage';
import BlogPost from './components/BlogPost';
import BlogPage from './components/BlogPage';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [selectedListToShare, setSelectedListToShare] = useState(null); // Add this line

console.log("Props in App component:", {selectedListToShare, setSelectedListToShare, });

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <Router>
      <div className="App">
        <Navbar />
        <Routes>
          <Route path="/" element={isAuthenticated ? <Navigate to="/lists" /> : <Login />} />
          <Route path="/register" element={<Registration />} />
          <Route path="/user-management" element={<UserManagement />} />
          <Route path="/lists" element={<Lists
                selectedListToShare={selectedListToShare}
                setSelectedListToShare={setSelectedListToShare}
              />} />
          <Route path="/user" element={isAuthenticated ? <UserPage /> : <Navigate to="/" />} />
          <Route path="/admin" element={<AdminPage />} />
	  <Route path="/blogpost" element={<BlogPost />} />
	  <Route path="/blog" element={<BlogPage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
