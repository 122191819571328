import React, { useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { db } from '../firebaseConfig'; // Import your Firebase instance
import { collection, addDoc } from 'firebase/firestore'; // Import Firestore functions
import './BlogPost.css'; // Assuming you've defined styles in a BlogPost.css file

const BlogPost = () => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [header, setHeader] = useState(''); // new state for the header

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };

  const handleHeaderChange = (event) => { // new handler for the header
    setHeader(event.target.value);
  };

  const submitPost = async () => {
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const postAsString = JSON.stringify(rawContentState, null, 2);

    try {
      // Add a new document with generated id into collection "blogposts"
      const docRef = await addDoc(collection(db, "blogposts"), {
        header, // save the header
        content: postAsString, // save the post content
        createdAt: new Date() // save the current date and time
      });
      console.log("Document written with ID: ", docRef.id);
      // After successfully saving the post, you might want to clear the editorState and the header field.
      setEditorState(EditorState.createEmpty());
      setHeader('');
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  return (
    <div>
      <h2>Create a Blog Post</h2>
      <input
        type="text"
        value={header}
        onChange={handleHeaderChange}
        placeholder="Enter post header..."
      />
      <div className="editor">
        <Editor
          editorState={editorState}
          onEditorStateChange={onEditorStateChange}
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName="editorClassName"
          toolbar={{options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'remove', 'history']}}
        />
      </div>
      <button onClick={submitPost}>Submit Post</button>
    </div>
  );
};

export default BlogPost;
