// src/components/Registration.js
import React, { useState } from "react";
import { auth, createUserWithEmailAndPassword, db, doc, setDoc, updateDoc } from "../firebaseConfig";
import { useNavigate } from "react-router-dom";
import { collection, addDoc } from 'firebase/firestore';


const Registration = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const createDefaultList = async (userId) => {
    try {
      const defaultListRef = await addDoc(collection(db, 'shoppingLists'), {
        createdBy: userId,
        name: 'My First List',
      });
      await updateDoc(doc(db, 'users', userId), {
        defaultList: defaultListRef.id
       });
    } catch (e) {
      console.error('Error adding default list: ', e);
     }
   };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const { user } = userCredential;

      // Create a user document in Firestore
      const userDocRef = doc(db, 'users', user.uid);
      await setDoc(userDocRef, {
        uid: user.uid,
        email: user.email,
        role: 'user', // Set the default role as 'user'
      });
	createDefaultList(user.uid);
      navigate("/lists");
    } catch (error) {
      alert("Failed to register. " + error.message);
    }
  };

  return (
    <div className="container">
      <h1>Registration</h1>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="email" className="form-label">Email address</label>
          <input type="email" className="form-control" id="email" value={email} onChange={(e) => setEmail(e.target.value)} />
        </div>
        <div className="mb-3">
          <label htmlFor="password" className="form-label">Password</label>
          <input type="password" className="form-control" id="password" value={password} onChange={(e) => setPassword(e.target.value)} />
        </div>
        <button type="submit" className="btn btn-primary">Register</button>
      </form>
    </div>
  );
};

export default Registration;
