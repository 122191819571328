// src/components/UserManagement.js
import React, { useState, useEffect } from "react";
import { auth, db } from "../firebaseConfig";
//import { doc, getDocs, collection, addDoc, query, onSnapshot, updateDoc, deleteDoc } from "firebase/firestore";
import { doc, getDocs, collection, deleteDoc } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";

const UserManagement = () => {
  const [user, setUser] = useState(null);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, setUser);
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchUsers = async () => {
      const usersCollection = collection(db, "users");
      const usersSnapshot = await getDocs(usersCollection);
      const usersList = usersSnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      setUsers(usersList);
    };
    fetchUsers();
  }, []);

  const deleteUser = async (userId) => {
    await deleteDoc(doc(db, "users", userId));
    setUsers(users.filter((user) => user.id !== userId));
  };

  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container">
      <h1>User Management</h1>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">Email</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr key={user.id}>
              <td>{user.email}</td>
              <td>
                <button className="btn btn-danger btn-sm" onClick={() => deleteUser(user.id)}>
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UserManagement;
