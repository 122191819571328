import React, { useState, useEffect } from "react";
import {
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  signInWithGoogle,
  auth,
  onAuthStateChanged
} from "../firebaseConfig";
import { useNavigate } from "react-router-dom";
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig'; 
import { browserLocalPersistence, setPersistence } from 'firebase/auth';

let isDocumentCreationInProgress = false;

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false); // New state variable
  const navigate = useNavigate();

useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userRef = doc(db, 'users', user.uid);
        const docSnapshot = await getDoc(userRef);
        if (!docSnapshot.exists() && !isDocumentCreationInProgress) {
          isDocumentCreationInProgress = true;
          try {
            await setDoc(userRef, {
              uid: user.uid,
              email: user.email,
              role: 'user',
            });
            console.log("User document created successfully.");
          } catch (error) {
            console.error("Failed to create user document. ", error);
          } finally {
            isDocumentCreationInProgress = false;
          }
        }
        if (!loading) {
          navigate("/lists");
        }
      }
      setLoading(false); // Set loading to false after navigation
    });

    return () => unsubscribe();
  }, [navigate, loading]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate("/lists");
    } catch (error) {
      alert("Failed to log in. " + error.message);
    }
  };

  const handleForgotPassword = async (email) => {
    try {
      await sendPasswordResetEmail(auth, email);
      alert("Password reset email sent! Please check your inbox.");
    } catch (error) {
      console.error("Error sending password reset email:", error);
      alert("Failed to send password reset email. Please try again.");
    }
  };

const handleGoogleLogin = async () => {
    try {
      setLoading(true); // Set loading to true when user starts logging in
      await setPersistence(auth, browserLocalPersistence);
      await signInWithGoogle(auth);
      // Navigation will be handled by the useEffect
    } catch (error) {
      alert("Failed to log in with Google. " + error.message);
      setLoading(false); // Set loading to false if there was an error
    }
};

  if (loading) {
    return (
      <div className="container mt-5">
        <h1 className="text-center">Logging in...</h1>
      </div>
    );
  } else {
    return (
      <div className="container">
        <h1>Login</h1>
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label htmlFor="email" className="form-label">
              Email address
            </label>
            <input
              type="email"
              className="form-control"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="password" className="form-label">
              Password
            </label>
            <input
              type="password"
              className="form-control"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <button type="submit" className="btn btn-primary">
            Login
          </button>
          <p>
            <button onClick={() => handleForgotPassword(email)}>
              Forgot Password?
            </button>
          </p>
        </form>
        <div className="mt-3">
          <button onClick={handleGoogleLogin} className="login-with-google-btn">
            Login with Google Account
          </button>
        </div>
      </div>
    );
  }
};

export default Login;

