import React, { useEffect, useState } from 'react';
import { db } from '../firebaseConfig';
import { collection, query, getDocs, orderBy, limit, startAfter } from 'firebase/firestore';

const BlogPage = () => {
    const [posts, setPosts] = useState([]);
    const [lastPost, setLastPost] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        loadPosts();
    }, []);

const loadPosts = async (last) => {
    setLoading(true);
    
    let postsRef = collection(db, 'blogposts');
    let postsQuery;

    // If we have a last post, start after it
    if (last) {
        postsQuery = query(postsRef, orderBy('createdAt', 'desc'), orderBy('id'), startAfter(last.createdAt, last.id), limit(5));
    } else {
        postsQuery = query(postsRef, orderBy('createdAt', 'desc'), orderBy('id'), limit(5));
    }

    const querySnapshot = await getDocs(postsQuery);
    let newPosts = [];

    querySnapshot.forEach((doc) => {
        console.log("Document data:", doc.data()); // print out raw document data
        let post = doc.data();
        post.id = doc.id;
        newPosts.push(post);
    });

    if(newPosts.length === 0) {
        console.log("No documents found!"); // log if no documents were found
    }

    // Set last post
    setLastPost(newPosts[newPosts.length - 1]);

    // Update state
    setPosts(prevPosts => [...prevPosts, ...newPosts]);
    setLoading(false);
};

    return (
        <div>
            <h1>Blog Page</h1>
            {posts.map((post, index) => (
                <div key={index}>
                    <h2>{post.title}</h2>
                    <p>{post.content}</p>
                </div>
            ))}
            {!loading && <button onClick={() => loadPosts(lastPost)}>Load more</button>}
            {loading && <h3>Loading...</h3>}
        </div>
    );
};

export default BlogPage;
