// src/firebaseConfig.js
import { initializeApp } from 'firebase/app';
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, onAuthStateChanged, signOut, updatePassword as updateUserPassword, reauthenticateWithCredential, EmailAuthProvider, getRedirectResult } from 'firebase/auth';
import { getFirestore, collection, addDoc, query, where, onSnapshot, deleteDoc, doc, getDocs, setDoc, getDoc } from 'firebase/firestore';
import { updateDoc } from 'firebase/firestore';
import { sendPasswordResetEmail, signInWithRedirect, GoogleAuthProvider } from 'firebase/auth';
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDKlB05uhD3fL4IvA376rYFO8IdvdK-L8U",
  authDomain: "shopping-list-24dad.firebaseapp.com",
  projectId: "shopping-list-24dad",
  storageBucket: "shopping-list-24dad.appspot.com",
  messagingSenderId: "853948224119",
  appId: "1:853948224119:web:b01a02ae16722b48462c72",
  measurementId: "G-P5Z03WSZFS"
};

export const signInWithGoogle = async (auth) => {
  const provider = new GoogleAuthProvider();
  await signInWithRedirect(auth, provider);
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

firebase.initializeApp(firebaseConfig);
const firestore = firebase.firestore();

export { app, auth, db, createUserWithEmailAndPassword, signInWithEmailAndPassword, onAuthStateChanged, signOut, updateUserPassword, reauthenticateWithCredential, EmailAuthProvider, collection, addDoc, query, where, onSnapshot, deleteDoc, doc, updateDoc, sendPasswordResetEmail, getDocs, setDoc, getDoc, firestore, getRedirectResult};
