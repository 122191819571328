import React, { useState, useEffect } from 'react';
import { auth, reauthenticateWithCredential, EmailAuthProvider, updateUserPassword, collection, db, onAuthStateChanged, getDocs, query, where } from '../firebaseConfig';
//import { auth, reauthenticateWithCredential, EmailAuthProvider, updateUserPassword, collection, addDoc, getFirestore } from '../firebaseConfig';

//const db = getFirestore();

const UserPage = () => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordUpdateStatus, setPasswordUpdateStatus] = useState('');
  const [, setShoppingLists] = useState([]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchShoppingLists(user);
	console.log("User UID:", user.uid); // Print the user uid
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const handleChangePassword = async (e) => {
    e.preventDefault();

    if (newPassword === confirmPassword && newPassword.length >= 6) {
      try {
        const user = auth.currentUser;
        const credential = EmailAuthProvider.credential(user.email, currentPassword);

        await reauthenticateWithCredential(user, credential);
        await updateUserPassword(user, newPassword);
        setPasswordUpdateStatus('success');
        setCurrentPassword('');
        setNewPassword('');
        setConfirmPassword('');
      } catch (error) {
        console.error('Error updating password:', error);
        setPasswordUpdateStatus('error');
      }
    } else {
      setPasswordUpdateStatus('invalid');
    }
  };

const fetchShoppingLists = async (user) => {
  try {
    const querySnapshot = await getDocs(
      query(
        collection(db, "shoppingLists"),
        where("createdBy", "==", user.uid)
      )
    );

    const fetchedLists = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    setShoppingLists(fetchedLists);
  } catch (error) {
    console.error("Error fetching shopping lists:", error);
  }
};


  return (
    <div className="container mt-5">
      <h1 className="text-center mb-4">Account</h1>
{/* ... Change password form ... */}
        <form onSubmit={handleChangePassword}>
        <h3>Change Password</h3>
        <div className="mb-3">
          <label htmlFor="currentPassword" className="form-label">
            Current Password
          </label>
          <input
            type="password"
            className="form-control"
            id="currentPassword"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="newPassword" className="form-label">
            New Password
          </label>
          <input
            type="password"
            className="form-control"
            id="newPassword"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="confirmPassword" className="form-label">
            Confirm New Password
          </label>
          <input
            type="password"
            className="form-control"
            id="confirmPassword"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </div>
        <button type="submit" className="btn btn-primary">
          Update Password
        </button>
        {passwordUpdateStatus === 'success' && (
          <div className="alert alert-success mt-3" role="alert">
            Password updated successfully!
          </div>
        )}
        {passwordUpdateStatus === 'error' && (
          <div className="alert alert-danger mt-3" role="alert">
            Error updating password. Please try again.
          </div>
        )}
        {passwordUpdateStatus === 'invalid' && (
          <div className="alert alert-warning mt-3" role="alert">
            New passwords do not match or are not strong enough (minimum 6 characters).
          </div>
        )}
      </form>
    </div>
  );
};

export default UserPage;
